<template>
  <div id="cloud-user-listings-layout">
    <div id="cloud-user-profile">

      <cloud-header title="Οι καταχωρήσεις μου"
                    subtitle="Δείτε και διαγράψτε τις καταχωρήσεις σας από το athlisis cloud."/>


      <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">

        <b-tab title="Διατροφικά πλάνα">

          <cloud-listing v-for="listing in dpListings" :key="listing.id" :item="listing" :show-edit-button="true" :show-delete-button="true"
          @deletedListing="fetchUserListings" @updateListing="updateListing"></cloud-listing>

        </b-tab>
        <b-tab title="Συνταγές">
          <cloud-listing v-for="listing in recipeListings" :key="listing.id" :item="listing" :show-edit-button="true" :show-delete-button="true"
                         @deletedListing="fetchUserListings"  @updateListing="updateListing"></cloud-listing>
        </b-tab>

        <b-tab title="Τρόφιμα">
          <cloud-listing v-for="listing in foodListings" :key="listing.id" :item="listing"
                         @deletedListing="fetchUserListings"></cloud-listing>
        </b-tab>

      </b-tabs>

    </div>
    <b-modal v-model="showEditListing" title="Επεξεργασία καταχώρησης" hide-footer>
      <cloud-modal v-if="showEditListing" :listing="editListing" @updated="updated"></cloud-modal>
    </b-modal>
  </div>

</template>

<script>
import CloudHeader from "@components/cloud/CloudHeader";
import CloudListing from "@components/cloud/CloudListing";
import CloudModal from "@views/dietPlan/details/CloudModal";
export default{

  components: {CloudHeader, CloudListing, CloudModal},


  computed:{
    dpListings(){
      if(!this.listings) return [];
      return this.listings.filter(x => !!x.dietPlanId);
    },
    foodListings(){
      if(!this.listings) return [];
      return this.listings.filter(x => !!x.foodId);
    },
    recipeListings(){
      if(!this.listings) return [];
      return this.listings.filter(x => !!x.recipeId);
    }
  },
  mounted(){
    this.fetchUserListings();
  },
  data(){
    return{
      listings: null,
      showEditListing: null,
      editListing: {}
    }
  },

  methods:{

    fetchUserListings(){
      this.$axios.get('/cloud/listings', {
        params:{
          userId: this.$route.params.userId
        }
      }).then(res=>{

        this.listings = res.data.rows;
      }).catch(e => {
        console.log(e);
      })
      },

    updateListing(listing){
      this.editListing = listing;
      this.showEditListing = true;
    },
    updated(){
      this.showEditListing = false;
      this.editListing = {};
      this.fetchUserListings()
    }
  }




}
</script>
